<template>
  <b-card-actions action-collapse title="Daftar Ajuan Barang">
    <b-overlay :show="loading">
      <b-row>
        <b-col class="my-1">
          <b-button v-b-modal.modal-tambah variant="primary" v-if="allowCreate() && myGudang" @click="add()">
            <feather-icon icon="PlusIcon" class="mr-50" />
            Ajuan Barang
          </b-button>
          <b-modal v-if="allowCreate() || allowUpdate()" v-model="showModal" no-close-on-backdrop no-close-on-esc id="modal-" ok-title="Tutup" ok-variant="secondary" ok-only centered title="Form ">
            <validation-observer ref="formRequestbarang">
              <div class="d-flex">
                <feather-icon icon="PackageIcon" size="19" />
                <h6 class="ml-50 mb-2">Data Ajuan Barang</h6>
              </div>
              <b-form>
                <b-row>
                  <!-- Tanggal Ajuan -->
                  <b-col cols="12">
                    <b-form-group label="Tanggal Ajuan" label-for="v-tanggal_waktu">
                      <validation-provider #default="{ errors }" name="tanggal_waktu" rules="required">
                        <b-form-input type="date" v-model="form.tanggal_waktu"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" v-if="isSPV">
                    <b-form-group label="Pilih Sales" label-for="v-sales_id">
                      <validation-provider #default="{ errors }" name="sales_id" :rules="{ required: true }">
                        <v-select v-model="form.sales_id" :options="sales_id" label="text"></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="Pilih Sales" label-for="v-sales_id" v-if="this.userData.level.id == 7">
                      <validation-provider #default="{ errors }" name="sales_id" :rules="{ required: true }">
                        <b-form-select :state="errors.length > 0 ? false : null" v-model="form.sales_id" :options="sales_id" id="v-sales_id" name="sales_id" disabled />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group label="Pilih Rute" label-for="v-rute">
                      <validation-provider #default="{ errors }" name="rute" rules="required">
                        <v-select label="text" :state="errors.length > 0 ? false : null" v-model="form.rutes" :options="routes" multiple></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- keterangan -->
                  <b-col cols="12">
                    <b-form-group label="Keterangan" label-for="v-keterangan">
                      <b-form-textarea v-model="form.keterangan" id="v-keterangan" placeholder="Isi Keterangan" />
                    </b-form-group>
                  </b-col>

                  <!-- submit and reset -->
                  <b-col cols="12">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="submit" variant="primary" class="mr-1"> Add </b-button>
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"> Reset </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" :options="pageOptions" class="w-50" />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group class="w-100">
            <div class="d-flex align-items-center w-100">
              <label class="d-inline-block text-sm-left mr-50">Status Ajuan</label>
              <b-form-select id="perPageSelect" v-model="selectedStatus" :options="statusOptions" class="w-50" />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group :label="$t('Filter')" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
            <b-input-group>
              <b-form-input id="filterInput" v-model="filter" type="search"  />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(i)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(tanggal_waktu)="{ item }">
              {{ humanDate(item.tanggal_waktu) }}
            </template>

            <template #cell(status)="{ item }">
              <b-badge :variant="setStatus(item.status).color">
                {{ setStatus(item.status).text }}
              </b-badge>
            </template>

            <template #cell(sales)="{ item }">
              {{ isAdminGudang && !isSales ? item.sales : getsalesName(item) }}
            </template>

            <template #cell(actions)="row">
              <b-button title="'Detail'" size="sm" @click.prevent="$router.push(`/ajuanbarang/detail/${row.item.id}`)" class="mr-1" variant="outline-success">
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button title="'Surat Jalan'" v-if="isAdminGudang && !isSales && row.item.status == 4" @click.prevent="downloadAttachment(row.item.id)" variant="outline-info" class="mr-1" size="sm">
                <feather-icon icon="FileTextIcon"></feather-icon>
              </b-button>
              <b-button title="'Ubah'" v-if="allowUpdate() && row.item.status < 3" size="sm" @click="edit(row.item)" class="mr-1" variant="outline-info">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button v-if="allowDelete()" title="'Hapus'" size="sm" @click="remove(row.item)" class="mr-1" variant="outline-danger">
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>

            <template #row-details="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
        </b-col>
      </b-row>
    </b-overlay>
  </b-card-actions>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BForm,
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BOverlay,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    BForm,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    selectedStatus(status) {
      if (status) {
        this.getData();
      }
    },
  },
  data() {
    return {
      salesGudang: null,
      myMobil: null,
      myRute: null,
      routes: [],
      loading: false,
      statusOptions: [
        {
          value: "all",
          text: "Semua",
          color: "light",
        },
        {
          value: 1,
          text: "PENDING",
          color: "danger",
        },
        {
          value: 2,
          text: "DISETUJUI SPV",
          color: "info",
        },
        {
          value: 3,
          text: "DISETUJUI GUDANG",
          color: "warning",
        },
        {
          value: 4,
          text: "SELESAI",
          color: "success",
        },
        {
          value: 5,
          text: "DIBATALKAN",
          color: "dark",
        },
      ],
      selectedStatus: "all",
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        sales_id: "",
        retur_id: null,
        tanggal_waktu: new Date(),
        keterangan: "",
        rutes: []
      },
      sales_id: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 20,
      pageOptions: [20, 50, 60],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  created() {
    this.form.tanggal_waktu = this.getCurrentDate();
    this.setFields();
  },
  async mounted() {
    if (this.isAdminGudang && !this.isSales) {
      this.getDataGudang();
    } else {
      this.getData();
    }

    this.getMyRoute()
    // this.getStatus()
    if (!this.isSales) {
      // if sales, does not choose sales
      this.getsales_id();
    }
  },
  methods: {
    async getSalesGudang() {
      const params = {
        karyawan_id: this.user && this.user.karyawan ? this.user.karyawan.id : null
      }

      const myGudangs = await this.$store.dispatch('gudang/getData', params)
      if(myGudangs.length < 1) {
        this.displayError({
          message: 'Sales yang dipilih belum memiliki mobil penyimpanan!'
        })
      }
      else {
        this.salesGudang = myGudangs[0]
      }

      
    },
    async getRoute() {
      const params = {
        sales_id: this.user && this.user.karyawan ? this.user.karyawan.id : null
      }
      const routes = await this.$store.dispatch('rute/getMyRouteJoin', params)
      this.routes = routes.map(item => ({value: item.id, text: item.nama_rute}))
    },
    async getMyRoute() {
      await this.getRoute()
      const params = {
        karyawan_id: this.user.karyawan.id,
        jenis: 2
      }

      const result = await this.$store.dispatch('gudang/getData', params)
      if(result && result.length > 0) {
        this.myRute = result[0].rute
        this.myMobil = result[0]
        if(this.myRute) this.form.rute_id = this.myRute.id
      }
    },
    setFields() {
      if (this.isSales) {
        this.fields = [
          {
            key: "i",
            label: "No",
          },
          { key: "no", label: "ID Ajuan Barang", sortable: true },
          { key: "tanggal_waktu", label: "Tanggal Ajuan", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "actions", label: "Aksi" },
        ];
      } else {
        this.fields = [
          {
            key: "i",
            label: "No",
          },
          { key: "no", label: "ID Ajuan Barang", sortable: true },
          { key: "sales", label: "Sales", sortable: true },
          { key: "tanggal_waktu", label: "Tanggal Ajuan", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "actions", label: "Actions" },
        ];
      }
    },
    async getStatus() {
      const currentMenu = await this.currentMenu();
      const params = {
        menu_id: currentMenu.id,
        level_id: this.user.level.id,
      };
      const statusAjuan = await this.$store.dispatch("statusrole/getData", params);
      statusAjuan.map((status) => {
        this.statusOptions.push({
          value: status.data_status,
          text: status.nama_status,
        });
      });
    },
    async downloadAttachment(request_id) {
      const payload = {
        request_id,
        id_gudang: this.myGudang.id,
      };
      try {
        this.loading = true;
        // create surat jalan
        const suratJalan = await this.$store.dispatch("requestbarang/createSuratJalan", [payload]);

        // download surat jalan
        const params = {
          request_id,
          gudangId: this.myGudang.id,
        };
        const response = await this.$store.dispatch("requestbarang/printSuratJalan", params);
        this.loading = false;
        window.open(URL.createObjectURL(response));
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    setStatus(status) {
      let result = {
        color: "primary",
        text: "",
      };
      result = this.statusOptions.find((st) => st.value == status);
      return result ? result : { color: "danger", text: "PENDING" };
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    async getRequestRoute(request_id) {
      const params = {
        request_id,
        sales_id: this.user && this.user.karyawan ? this.user.karyawan.id : null
      }

      const requestRoute = await this.$store.dispatch('requestbarang/getRoute', params)
      if(requestRoute.length > 0) {
        this.form.rutes = requestRoute.map(item => ({
          id:item.id,
          text: item.rute ? item.rute.nama_rute : null,
          value: item.rute ? item.rute.id : null,
        }))
      }
    },
    async edit(item) {
      const sales_id = item.sales_id
      this.form = item;
      const sales = this.sales_id.find(sl => sl.id == item.sales.id)
      if(sales) {
        this.form.sales_id = {
          value: sales.id,
          id: sales.id,
          text: sales.nama_lengkap
        }
      }
      if(this.myRute) {
        this.form.rute_id = this.myRute.id
      }
      this.form.tanggal_waktu = this.form.tanggal_waktu.split(' ')[0]
      // if (this.form.sales && !this.isSales) this.form.sales_id = this.form.sales.id;
      this.id = item.id;

      await this.getRequestRoute(this.id)
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data ajuan barang ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((res) => {
        if(res.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("requestbarang/save", [item])
            .then(() => {
              this.getData();
              this.displaySuccess({
                message: "Ajuan berhasil dihapus",
              });
              this.resetForm();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
      this.form.tanggal_waktu = this.getCurrentDate();
      this.form.keterangan = null;
      if (!this.isSales) {
        this.form.sales_id = null;
      }
      this.form.rutes = []
    },
    submit() {
      this.$refs.formRequestbarang.validate().then(async (success) => {
        if (success) {
          if (this.isSales) {
            this.form.sales_id = this.user.karyawan.id;
          }
          else {
            this.form.sales_id = this.form.sales_id.id
          }
          if (this.id) {
            this.form.id = this.id;
          }
          if(this.myGudang) {
            this.form.id_gudang = this.myGudang.id;
          }
          else {
            const myGudangs = await this.$store.dispatch('gudang/getData', {
              karyawan_id: this.form.sales_id.id
            })
            this.form.id_gudang = myGudangs && myGudangs.length > 0 ? myGudangs[0].id : null
          }
          if (!this.form.status) {
            this.form.status = 1;
          }
          if (!this.form.keterangan) {
            this.form.keterangan = "-";
          }
          const payload = this.form;
          try {
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("requestbarang/save", [payload]);
            if(payload.rutes.length > 0) {
              const rutePayload = payload.rutes.map(item => {
                const payload = {
                  request_id: ajuan.id,
                  sales_id: this.user && this.user.karyawan ? this.user.karyawan.id : null,
                  rute_id: item.value
                }

                if(item.id) {
                  payload.id = item.id
                }

                return payload 
              })

              await this.$store.dispatch('requestbarang/saveRoute', rutePayload)
            }
            if(this.isSales) {
              // send notif
              const key = 'ajuan_barang'
              const value = {
                level: this.user.level.id,
                data: ajuan
              }

              this.eventSocket(key, value)
            }
            this.label = "Submit";
            this.$router.push(`/ajuanbarang/detail/${ajuan.id}`);
          } catch (e) {
            console.error(e);
            this.displayError(e);
            return false;
          }
        }
      });
    },
    async getDataGudang() {
      const params = {
        gudang_id: this.myGudang.id,
      };

      this.loading = true;
      const ajuans = await this.$store.dispatch("requestbarang/getDataGudang", params);
      const pendingAjuan = ajuans.filter(ajuan => ajuan.status == 1)
      const approveSpvAjuan = ajuans.filter(ajuan => ajuan.status == 2)
      const approveGudangAjuan = ajuans.filter(ajuan => ajuan.status == 3)
      const completeAjuan = ajuans.filter(ajuan => ajuan.status == 4)
      const canceledAjuan = ajuans.filter(ajuan => ajuan.status == 5)
      this.items = [...approveSpvAjuan, ...approveGudangAjuan, ...pendingAjuan, ...completeAjuan, ...canceledAjuan]
      this.totalRows = this.items.length;
      this.loading = false;
    },
    async getData() {
      // set payload if sales itself, filter per sales itself
      let params = this.isSales ? { sales_id: this.user.karyawan.id, order: "desc" } : { order: "desc" };

      if (this.selectedStatus && this.selectedStatus != "all") {
        params.status = this.selectedStatus;
      }
      this.loading = true;
      const ajuans = await this.$store.dispatch("requestbarang/getData", params);
      const pendingAjuan = ajuans.filter(ajuan => ajuan.status == 1)
      const approveSpvAjuan = ajuans.filter(ajuan => ajuan.status == 2)
      const approveGudangAjuan = ajuans.filter(ajuan => ajuan.status == 3)
      const completeAjuan = ajuans.filter(ajuan => ajuan.status == 4)
      const canceledAjuan = ajuans.filter(ajuan => ajuan.status == 5)
      let finalAjuan = [...pendingAjuan, ...approveSpvAjuan, ...approveGudangAjuan, ...completeAjuan, ...canceledAjuan]
      if(this.isSales) {
        finalAjuan = [...approveGudangAjuan, ...pendingAjuan, ...approveSpvAjuan, ...completeAjuan, ...canceledAjuan]
      }
      this.loading = false;
      this.items = finalAjuan;
      this.totalRows = finalAjuan.length;
    },
    getsales_id() {
            let payload = {jabatan_id: 8}
      if (this.isCabang){
        payload.member_id = this.user.member_id
      }
      this.$store
        .dispatch("karyawan/getData", payload)
        .then(() => {
          let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
          karyawan.map((item) => {
            item.value = item.id;
            item.text = item.nama_lengkap;
          });

          this.sales_id = karyawan;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getsalesName(data) {
      return data.sales ? data.sales.nama_lengkap : "-";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
